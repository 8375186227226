var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"personal-auth-form"},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',_vm._b({attrs:{"label":_vm.$t('layout.user.auth.form.name.title')}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'personName',
          {
            rules: [
              {
                required: true,
                message: _vm.$t('layout.user.auth.form.name.placeholder'),
              },
            ],
          },
        ]),expression:"[\n          'personName',\n          {\n            rules: [\n              {\n                required: true,\n                message: $t('layout.user.auth.form.name.placeholder'),\n              },\n            ],\n          },\n        ]"}],attrs:{"size":"large","placeholder":_vm.$t('layout.user.auth.form.name.placeholder')}})],1),_c('a-form-item',_vm._b({attrs:{"label":_vm.$t('layout.user.auth.form.certificates.title')}},'a-form-item',_vm.formItemLayout,false),[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'certificate',
          {
            rules: [
              {
                required: true,
                message: `请选择${_vm.$t(
                  'layout.user.auth.form.certificates.title'
                )}`,
              },
            ],
          },
        ]),expression:"[\n          'certificate',\n          {\n            rules: [\n              {\n                required: true,\n                message: `请选择${$t(\n                  'layout.user.auth.form.certificates.title'\n                )}`,\n              },\n            ],\n          },\n        ]"}],attrs:{"size":"large"}},_vm._l((_vm.certificates),function(item){return _c('a-select-option',{key:item.key,attrs:{"value":item.value}},[_vm._v(_vm._s(item.key))])}),1)],1),_c('a-form-item',_vm._b({attrs:{"label":_vm.$t('layout.user.auth.form.no.title')}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'no',
          {
            rules: [
              {
                required: true,
                message: _vm.$t('layout.user.auth.form.no.placeholder'),
              },
            ],
          },
        ]),expression:"[\n          'no',\n          {\n            rules: [\n              {\n                required: true,\n                message: $t('layout.user.auth.form.no.placeholder'),\n              },\n            ],\n          },\n        ]"}],attrs:{"size":"large","placeholder":_vm.$t('layout.user.auth.form.no.placeholder')}})],1),_c('a-form-item',_vm._b({attrs:{"label":_vm.$t('layout.user.auth.form.phone.title')}},'a-form-item',_vm.formItemLayout,false),[_c('a-input-search',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'phone',
          {
            rules: [
              {
                required: true,
                message: _vm.$t('layout.user.auth.form.phone.placeholder'),
              },
            ],
          },
        ]),expression:"[\n          'phone',\n          {\n            rules: [\n              {\n                required: true,\n                message: $t('layout.user.auth.form.phone.placeholder'),\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":_vm.$t('layout.user.auth.form.phone.placeholder'),"size":"large"}},[_c('a-button',{style:(_vm.resendBtnStyle),attrs:{"slot":"enterButton","type":"primary","ghost":""},slot:"enterButton"},[_vm._v(_vm._s(_vm.countDown > 0 ? `重新发送(${_vm.countDown}s)` : "获取验证码"))])],1)],1),_c('a-form-item',_vm._b({staticClass:"statement"},'a-form-item',_vm.statementLayout,false),[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:(['agreement', { valuePropName: 'checked' }]),expression:"['agreement', { valuePropName: 'checked' }]"}],attrs:{"type":"primary","size":"large"}},[_vm._v(" "+_vm._s(_vm.$t("layout.user.auth.form.statement"))+" ")])],1),_c('a-form-item',[_c('a-button',{style:(_vm.submitBtnStyle),attrs:{"size":"large","type":"primary","html-type":"submit","shape":"round"}},[_vm._v("提交信息")])],1)],1),_c('auth-submit-success-modal',{ref:"authSubmitSuccessModal"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }