<template>
  <div class="content-container">
    <card-title
      :title="$t('layout.user.auth.personal.title')"
      :description="$t('layout.user.auth.description')"
    />
    <personal-auth-form />
  </div>
</template>

<script>
import CardTitle from "@/components/CardTitle";
import PersonalAuthForm from "@/views/user/personal-auth/PersonalAuthForm";

export default {
  components: {
    CardTitle,
    PersonalAuthForm,
  },
  data() {
    return {};
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.info-wrap {
  padding-left: 80px;
}
</style>
