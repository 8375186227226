<template>
  <div class="personal-auth-form">
    <a-form :form="form" @submit="handleSubmit">
      <!--姓名-->
      <a-form-item
        :label="$t('layout.user.auth.form.name.title')"
        v-bind="formItemLayout"
      >
        <a-input
          size="large"
          :placeholder="$t('layout.user.auth.form.name.placeholder')"
          v-decorator="[
            'personName',
            {
              rules: [
                {
                  required: true,
                  message: $t('layout.user.auth.form.name.placeholder'),
                },
              ],
            },
          ]"
        />
      </a-form-item>
      <!--证件类型-->
      <a-form-item
        :label="$t('layout.user.auth.form.certificates.title')"
        v-bind="formItemLayout"
      >
        <a-select
          size="large"
          v-decorator="[
            'certificate',
            {
              rules: [
                {
                  required: true,
                  message: `请选择${$t(
                    'layout.user.auth.form.certificates.title'
                  )}`,
                },
              ],
            },
          ]"
        >
          <a-select-option
            v-for="item in certificates"
            :key="item.key"
            :value="item.value"
            >{{ item.key }}</a-select-option
          >
        </a-select>
      </a-form-item>
      <!--证件号码-->
      <a-form-item
        :label="$t('layout.user.auth.form.no.title')"
        v-bind="formItemLayout"
      >
        <a-input
          size="large"
          :placeholder="$t('layout.user.auth.form.no.placeholder')"
          v-decorator="[
            'no',
            {
              rules: [
                {
                  required: true,
                  message: $t('layout.user.auth.form.no.placeholder'),
                },
              ],
            },
          ]"
        />
      </a-form-item>
      <!--手机验证码-->
      <a-form-item
        :label="$t('layout.user.auth.form.phone.title')"
        v-bind="formItemLayout"
      >
        <a-input-search
          :placeholder="$t('layout.user.auth.form.phone.placeholder')"
          size="large"
          v-decorator="[
            'phone',
            {
              rules: [
                {
                  required: true,
                  message: $t('layout.user.auth.form.phone.placeholder'),
                },
              ],
            },
          ]"
        >
          <a-button
            slot="enterButton"
            type="primary"
            ghost
            :style="resendBtnStyle"
            >{{
              countDown > 0 ? `重新发送(${countDown}s)` : "获取验证码"
            }}</a-button
          >
        </a-input-search>
      </a-form-item>
      <!--声明-->
      <a-form-item class="statement" v-bind="statementLayout">
        <a-checkbox
          type="primary"
          size="large"
          v-decorator="['agreement', { valuePropName: 'checked' }]"
        >
          {{ $t("layout.user.auth.form.statement") }}
        </a-checkbox>
      </a-form-item>
      <!--提交按钮-->
      <a-form-item>
        <a-button
          size="large"
          type="primary"
          html-type="submit"
          :style="submitBtnStyle"
          shape="round"
          >提交信息</a-button
        >
      </a-form-item>
    </a-form>
    <auth-submit-success-modal ref="authSubmitSuccessModal" />
  </div>
</template>
<script>
import { Input, Select, Form, Button, Checkbox } from "ant-design-vue";
import { convertPxToVw } from "@/utils/format";
import AuthSubmitSuccessModal from "@/views/user/AuthSubmitSuccessModal";

const testData = [
  {
    key: "身份证",
    value: 0,
  },
];

export default {
  components: {
    [Input.name]: Input,
    [Input.Search.name]: Input.Search,
    [Select.name]: Select,
    [Select.Option.name]: Select.Option,
    [Form.name]: Form,
    [Form.Item.name]: Form.Item,
    [Checkbox.name]: Checkbox,
    [Button.name]: Button,
    AuthSubmitSuccessModal,
  },
  data() {
    return {
      certificates: testData,
      countDown: 9,
      config: {
        rules: [
          { type: "object", required: true, message: "Please select time!" },
        ],
      },
      rangeConfig: {
        rules: [
          { type: "array", required: true, message: "Please select time!" },
        ],
      },
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 10 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12 },
        },
      },
      statementLayout: {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 13,
            offset: 10,
          },
        },
      },
      form: this.$form.createForm(this),
      submitBtnStyle: {
        width: convertPxToVw(320),
        // margin: "0 auto",
        // marginLeft: convertPxToVw(160),
        marginLeft: "35%",
      },
      resendBtnStyle: {
        height: convertPxToVw(40),
        fontSize: convertPxToVw(14),
      },
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this);
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.$refs.authSubmitSuccessModal.setModalVisible(true);
      this.form.validateFields((err, fieldsValue) => {
        if (err) {
          return;
        }
        console.log(fieldsValue);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/helper.scss";
@import "@/styles/scss/variants.scss";

.personal-auth-form {
  width: 640px;
  margin: 80px auto 0;

  //.code {
  //  ::v-deep .ant-form-item-control-wrapper {
  //    //width: 320px;
  //  }
  //}

  .statement {
    //width: 336px;
    //margin-left: 200px;
    margin-bottom: 20px !important;
    margin-top: 50px;

    ::v-deep .ant-checkbox-wrapper {
      line-height: 27px;
      font-size: 16px;
      color: $primary-text-color;
    }
  }

  .ant-form-item {
    //display: flex;
    margin-bottom: 10px;

    ::v-deep .ant-form-item-label {
      //width: 200px;
      color: $primary-text-color;
    }
    input {
      //width: 320px;
    }

    .ant-select {
      //width: 320px;
    }
  }
}
</style>
