<template>
  <div ref="authSubmitSuccessModal" class="auth-success-modal">
    <a-modal
      title="提示"
      centered
      :visible="showModal"
      :closable="true"
      maskClosable
      :getContainer="() => $refs.authSubmitSuccessModal"
      :width="modalWidth"
      :dialogStyle="dialogStyle"
      @cancel="confirm"
      :afterClose="confirm"
    >
      <i slot="closeIcon" class="iconfont icon-icon_tianjia-xian"></i>
      <div class="content">
        <i class="iconfont icon-chenggong-17"></i>
        <span>提交信息成功，请耐心等待审核，谢谢！</span>
      </div>
      <div slot="footer">
        <!--<a-button-group>-->
        <a-button
          type="primary"
          size="large"
          :style="btnStyle"
          @click="confirm"
          >{{ $t("layout.authModal.confirm") }}</a-button
        >
        <!--<a-button-->
        <!--  type="primary"-->
        <!--  size="large"-->
        <!--  ghost-->
        <!--  :style="btnStyle"-->
        <!--  @click="() => setModalVisible(false)"-->
        <!--  >{{ $t("layout.authModal.cancel") }}</a-button-->
        <!--&gt;-->
        <!--</a-button-group>-->
      </div>
    </a-modal>
  </div>
</template>

<script>
import { Modal, Button } from "ant-design-vue";
import { convertPxToVw } from "@/utils/format";

export default {
  components: {
    [Modal.name]: Modal,
    [Button.name]: Button,
    [Button.Group.name]: Button.Group,
  },
  data() {
    return {
      showModal: false,
      modalWidth: convertPxToVw(538),
      dialogStyle: {
        paddingBottom: 0,
        width: convertPxToVw(538),
        // minWidth: "500px",
      },
      btnStyle: {
        width: convertPxToVw(140),
      },
      authType: 0,
    };
  },
  props: {},
  methods: {
    setModalVisible(bool) {
      this.showModal = bool;
    },
    confirm() {
      this.showModal = false;
      this.$router.replace("/user/info");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/variants.scss";
@import "@/styles/scss/helper.scss";

.auth-success-modal {
  ::v-deep .ant-modal-content {
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    .ant-modal-close-x {
      height: 40px;
      line-height: 40px;
    }

    .icon-icon_tianjia-xian {
      color: #999;
      font-size: 20px;
      display: inline-block;
      transform: rotate(45deg);
    }

    .ant-modal-header {
      border-radius: 10px 10px 0 0;
      background: #f3f6ff;
      height: 40px;
      padding: 0 0 0 24px;
      @include flex(row, null, center);
      border-bottom: none;

      .ant-modal-title {
        font-size: $font-size-description;
        color: #333333;
      }
    }

    .ant-modal-body {
      border-radius: 10px;
    }

    .ant-modal-footer {
      border-top: none;
      padding-top: 0;
      padding-bottom: 26px;
    }
  }

  .content {
    padding: 36px 60px;
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #39425c;

    .icon-chenggong-17 {
      color: #74d3b8;
      font-size: 34px;
      margin-right: 16px;
    }
  }
}

//.auth-modal {
//  ::v-deep .ant-modal-content {
//    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.1);
//    border-radius: 10px;
//
//    .ant-modal-header {
//      border-radius: 10px 10px 0 0;
//      background: #f3f6ff;
//      height: 40px;
//      padding: 0 0 0 24px;
//      @include flex(row, null, center);
//      border-bottom: none;
//
//      .ant-modal-title {
//        font-size: $font-size-description;
//        color: #333333;
//      }
//    }
//
//    .ant-modal-body {
//      border-radius: 10px;
//    }
//
//    .ant-modal-footer {
//      border-top: none;
//    }
//  }

//.auth-method {
//  height: 242px;
//  @include flex();
//  color: $primary-text-color;
//  background: $white;
//  .personal {
//    width: 50%;
//    padding: 30px 32px 0;
//    border: 1px solid #eef4ff;
//    border-right: none;
//  }
//
//  .enterprise {
//    width: 50%;
//    border: 1px solid #eef4ff;
//  }
//
//  .personal,
//  .enterprise {
//    padding: 30px 32px 0;
//
//    &.active {
//      background: #eef4ff;
//    }
//  }
//
//  .title-wrap {
//    margin-bottom: 20px;
//  }
//
//  .title {
//    font-weight: bold;
//  }
//
//  .description {
//    line-height: 27px;
//    font-size: 16px;
//    p {
//      margin-bottom: 0;
//    }
//  }
//}
//}
</style>
